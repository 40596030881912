import {
  TeliaButton,
  TeliaHeading,
  TeliaP,
  TeliaRow,
} from '@teliads/components/react';
import { Link, navigate, PageProps } from 'gatsby';
import React from 'react';
import { Layout, Grid } from '../components/Layout';
import ContentfulRichText from '../components/ContentfulRichText';
import { ContentfulCheckListWrapper } from '../models/ContentfulTypes';
import '../styles/checklist.scss';
interface Props extends PageProps {
  pageContext: {
    entity: ContentfulCheckListWrapper;
    nextPageUrl: string;
    contentfulId: string;
    deviceSlug: string;
  };
}

export default ({ pageContext }: Props) => {
  const { entity, nextPageUrl, contentfulId, deviceSlug } = pageContext;

  return (
    <Layout
      contentfulId={contentfulId}
      deviceSlug={deviceSlug}
      title={entity.title}
    >
      <Grid>
        <TeliaRow className="column gap center padding--vertical">
          <TeliaHeading tag="h2" variant="display-100">
            {entity.title}
          </TeliaHeading>
          <TeliaP>{entity.description?.description}</TeliaP>
          {entity.checklisElements?.map((checklistItem, index) => {
            return (
              <article
                className="checklist--item"
                key={`checklist-item-${checklistItem.contentful_id}`}
              >
                {checklistItem.animation?.file?.url ? (
                  <video width="320" height="240" loop>
                    <source
                      src={checklistItem.animation?.file?.url}
                      type="video/mp4"
                    />
                    <img src={checklistItem.mediaAsset?.file?.url} alt="" />
                  </video>
                ) : (
                  <img src={checklistItem.mediaAsset?.file?.url} alt="" />
                )}
                <TeliaHeading tag="h3">{checklistItem.title}</TeliaHeading>
              </article>
            );
          })}
          <TeliaHeading tag="h3" variant="title-100">
            {entity.help?.title}
          </TeliaHeading>
          {entity.help?.description?.raw && (
            <ContentfulRichText raw={entity.help.description.raw} />
          )}
          {nextPageUrl && (
            <TeliaButton
              variant="primary"
              onClick={() => navigate(nextPageUrl)}
            >
              {entity.nextButtonText || 'No Text in ContentFul'}
            </TeliaButton>
          )}
        </TeliaRow>
      </Grid>
    </Layout>
  );
};
